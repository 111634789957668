

















































































import Component, { mixins } from "vue-class-component";
import { Navigation } from "@/mixins/navigation";
import Loader from "@/components/General/Loader.vue";
import { Notification } from "@/models/notification.interface";
import { Watch } from "vue-property-decorator";

@Component({
  components: {
    Loader,
  },
})
export default class WishlistButton extends mixins(Navigation) {
  loader = false;
  paginationLoader = false;
  selectedPaymentPlatform = {};
  pagination = {
    itemsPerPage: parseInt(process.env.VUE_APP_DEFAULT_PAGINATION),
    totalItems: 0,
    page: 1,
  };

  @Watch("selectedPaymentPlatform")
  private handler() {
    this.$emit("selectedPaymentPlatformChanged", this.selectedPaymentPlatform);
  }

  private async created() {
    this.loader = true;
    await this.$store
      .dispatch("platform/getAllPaymentPlatforms", {
        page: 1,
        size: 10,
        text: "",
      })
      .catch(() => {
        const Error: Notification = {
          message: this.$tc("PaymentPlatforms.get-error"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: true,
        };
        this.$store.dispatch("notifications/showNotification", Error);
      })
      .finally(() => {
        this.loader = false;
      });

    await this.$store
      .dispatch("users/getUserBalanceById", this.userID)
      .catch(() => {
        const Error: Notification = {
          message: this.$t("Users.fetchError.userBalance", {
            name: this.$route.params.id,
          }) as string,
          timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: true,
        };

        this.$store.dispatch("notifications/showNotification", Error);
      });
  }

  private get paymentPlatforms() {
    const platforms = this.$store.getters["platform/getAllPaymentPlatforms"];
    const filteredPlatforms = platforms.platforms.filter(
      (platform: any) => platform.status.name == this.$constants.STATUS.ACTIVE
    );

    this.pagination.totalItems = platforms.totalItems;

    return filteredPlatforms;
  }

  private async showMorePaymentPlatforms() {
    this.paginationLoader = true;
    await this.$store
      .dispatch("platform/getMorePaymentPlarformsForClient", {
        page: this.pagination.page + 1,
        size: this.pagination.itemsPerPage,
        text: "",
      })
      .catch(() => {
        const Error: Notification = {
          message: this.$tc("PaymentPlatforms.payments-error"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: true,
        };
        this.$store.dispatch("notifications/showNotification", Error);
      })
      .finally(() => {
        this.paginationLoader = false;
        this.pagination.page++;
      });
  }

  private get totalPages() {
    const total = this.pagination.totalItems / this.pagination.itemsPerPage;
    if (total > Math.round(total)) {
      return Math.round(total) + 1;
    } else {
      return Math.round(total);
    }
  }

  private get userID(): any {
    let user = this.$store.getters["authentication/getUser"];
    if (user) {
      return user.id;
    } else {
      return {};
    }
  }

  private get userBalance(): any {
    return this.$store.getters["users/getUserBalance"];
  }

  private get image(): string {
    let logo = this.$store.getters["system/getLogo"];
    if (logo) {
      return logo.url;
    } else {
      return "";
    }
  }
}
