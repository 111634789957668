var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loader)?_c('v-row',[_c('v-col',[_c('Loader',{attrs:{"message":_vm.$tc('PaymentPlatforms.loading')}})],1)],1):_c('v-row',{staticClass:"my-12"},[_c('v-col',{staticClass:"d-flex justify-center align-center flex-wrap"},[_vm._l((_vm.paymentPlatforms),function(platform){return _c('v-card',{key:platform.id,staticClass:"ma-3 pa-3",staticStyle:{"cursor":"pointer"},attrs:{"width":"250","elevation":"3","color":_vm.selectedPaymentPlatform.id == platform.id
          ? 'blue-grey lighten-4'
          : 'white'},on:{"click":function () {
          _vm.selectedPaymentPlatform = platform;
        }}},[_c('p',{staticClass:"text-center"},[_vm._v(_vm._s(platform.name))]),_c('div',{staticClass:"d-flex justify-center"},[_c('v-img',{attrs:{"src":platform.logo.url,"contain":"","height":"200"}})],1)])}),(_vm.userBalance.amount > 0)?_c('v-card',{staticClass:"ma-3 pa-3",staticStyle:{"cursor":"pointer"},attrs:{"width":"250","height":"264","elevation":"3","color":_vm.selectedPaymentPlatform.balance ? 'blue-grey lighten-4' : 'white'},on:{"click":function () {
          _vm.selectedPaymentPlatform = {
            balance: true,
            current_balance: this$1.userBalance,
          };
        }}},[_c('p',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.$tc("PaymentPlatforms.balance")))]),_c('div',{staticClass:"d-flex justify-center"},[_c('v-img',{attrs:{"src":_vm.image,"contain":"","height":"150"}})],1),_c('p',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.$tc("PaymentPlatforms.available"))+": "),_c('b',[_vm._v(_vm._s(_vm._f("currency")(_vm.userBalance.amount)))])])]):_vm._e(),(_vm.totalPages > _vm.pagination.page)?_c('v-card',{staticClass:"ma-3 pa-3",staticStyle:{"cursor":"pointer"},attrs:{"width":"250","elevation":"3"},on:{"click":_vm.showMorePaymentPlatforms}},[_c('p',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.$tc("PaymentPlatforms.more")))]),_c('div',{staticClass:"text-center"},[(!_vm.paginationLoader)?_c('v-icon',{attrs:{"x-large":""}},[_vm._v("mdi-plus")]):_vm._e(),(_vm.paginationLoader)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":_vm.$constants.COLOR.LOADER}}):_vm._e()],1)]):_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }