













































































import { StyleCheck } from "@/mixins/style-check";
import Component, { mixins } from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class BillInfo extends mixins(StyleCheck) {
  @Prop({
    default: () => {
      return {};
    },
  })
  product!: { name: string; price: string; total: string; units: number };
  get days() {
    return process.env.VUE_APP_BILL_DAYS || "";
  }

  dialog = false;
  confirm() {
    this.dialog = false;
    this.$emit("confirm");
  }
}
