






























































import { FormValidations } from "@/mixins/form-validations";
import Component, { mixins } from "vue-class-component";
import { Navigation } from "@/mixins/navigation";
import { Notification } from "@/models/notification.interface";
import { Prop, Watch } from "vue-property-decorator";
import { StyleCheck } from "@/mixins/style-check";

@Component({})
export default class BalancePayment extends mixins(
  Navigation,
  FormValidations,
  StyleCheck
) {
  @Prop() paymentPlatform: any;
  @Prop({ default: false }) makePayment!: boolean;

  $refs!: {
    balancePayment: HTMLFormElement;
  };

  balancePayment: { amount: any; description: string } = {
    amount: 0,
    description: "",
  };

  @Watch("makePayment")
  private createBalancePayment(val) {
    this.balancePayment.amount = parseFloat(this.balancePayment.amount);
    if (
      val &&
      this.$refs.balancePayment.validate() &&
      this.balancePayment.amount <= this.paymentPlatform.current_balance.amount
    ) {
      const payload = {
        payment_balance: {
          amount: this.balancePayment.amount,
          description: this.balancePayment.description,
        },
        bill: {
          id: this.singleBill.id,
        },
      };
      this.$store
        .dispatch("payment/createBalancePayment", payload)
        .then((response) => {
          const Success: Notification = {
            message: this.$tc("BalancePayments.success"),
            timeout: this.$constants.NOTIFICATION_TIMEOUT.SUCCESS,
            top: true,
            bottom: false,
            left: false,
            right: false,
            currentPath: this.$route.fullPath,
            persistent: true,
            error: false,
          };

          this.$store.dispatch("notifications/showNotification", Success);
          this.$emit("paymentCreated", response.data.id);
        })
        .catch(() => {
          const Error: Notification = {
            message: this.$tc("BalancePayments.error"),
            timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
            top: true,
            bottom: false,
            left: false,
            right: false,
            currentPath: this.$route.fullPath,
            error: true,
          };

          this.$store.dispatch("notifications/showNotification", Error);
          this.$emit("formError");
        });
    } else {
      if (
        this.balancePayment.amount > this.paymentPlatform.current_balance.amount
      ) {
        const Error: Notification = {
          message: this.$tc("BalancePayments.exceeds"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: true,
        };

        this.$store.dispatch("notifications/showNotification", Error);
      }
      this.$emit("formError");
    }
  }

  private get checkoutInfo() {
    return this.$store.getters["checkout/getCheckoutInformation"];
  }

  private get singleBill() {
    return this.$store.getters["checkout/getSingleBill"];
  }
}
